<template>
  <div class="tasks-create">
    <Banner>
      <div class="banner-content">
        <MeepIconTask
          class="banner-content__icon banner-content__icon-stroke"
        />
        <span class="banner-content__text">{{ $t("menu.task") }}</span>
      </div>
    </Banner>
    <div class="tasks-create-content page-layout">
      <md-card class="meep-form">
        <PageHeader
          :title="$t('task.create-title')"
          :has-back="true"
          @back="goBack"
        />
        <!-- le formulaire de création -->
        <md-card-content>
          <div class="meep-form-column meep-form-single-column">
            <!-- Titre -->
            <div class="meep-input">
              <md-field :class="{ 'md-invalid': errors.has('title') }">
                <label>Titre *</label>

                <md-input
                  v-model="task.title"
                  v-validate="'required'"
                  name="title"
                  type="text"
                />
                <span v-show="errors.has('title')" class="md-error">
                  {{ errors.first("title") }}
                </span>
              </md-field>
            </div>

            <!-- Description-->
            <div class="meep-textarea">
              <ckeditor
                v-model="task.description"
                placeholder="Description"
                :editor="editor"
                :config="editorConfig"
                tag-name="textarea"
              />
            </div>

            <!-- Date Limite-->
            <div class="meep-input">
              <md-datepicker
                v-model="task.deadline"
                :md-disabled-dates="isDateInvalid"
              >
                <label>Date Limite *</label>
              </md-datepicker>
            </div>

            <div class="meep-input">
              <md-field>
                <label>
                  {{ $t("form.company") }}
                </label>
                <md-select
                  v-model="task.company_id"
                  name="collab"
                  @md-selected="selectCompany"
                >
                  <md-option
                    v-for="company in companies"
                    :key="company.id"
                    :value="company.id"
                  >
                    {{ company.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="meep-input">
              <md-field>
                <label>Assigné à</label>
                <md-select v-model="task.user_id" name="collab">
                  <md-option
                    v-for="user in users"
                    :key="user.id"
                    :value="user.id"
                  >
                    {{ user.firstname }} {{ user.lastname }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <div class="md-layout md-alignment-center-right">
              <md-button
                class="md-raised md-primary"
                @click.native="createTask"
              >
                {{ $t("tasks.create.button-text") }}
              </md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import taskModel from "../../model/tasks";
import usersModel from "../../model/users";
import companiesModel from "../../model/companies";
import { sortByKey } from "@/services/util";
import Banner from "@/components/Banner";
import MeepIconTask from "@/components/icons/MeepIconTask.vue";
import _ from "lodash";

/* Composants */
import { USERS } from "@/constants";
import PageHeader from "@/components/PageHeader";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditorConfig from "@/mixins/CKEditorConfig";

export default {
  name: "CreateTask",

  components: {
    Banner,
    MeepIconTask,
    ckeditor: CKEditor.component,
    PageHeader,
  },

  data() {
    return {
      task: {
        title: "",
        description: "",
        deadline: new Date(),
        user_id: "",
        company_id: "",
      },

      editor: ClassicEditor,

      allUsers: [],

      companies: [],

      collaboratorsId: [],

      showTopButton: true,
    };
  },

  mixins: [CKEditorConfig],

  computed: {
    users() {
      return this.allUsers.filter(
        user =>
          user.scope === USERS.ADMIN ||
          _.includes(this.collaboratorsId, user.id)
      );
    },
  },

  async mounted() {
    try {
      const users = await usersModel.getUsersInOrganization();

      this.allUsers = users.filter(
        user => user.scope === USERS.ADMIN || user.scope === USERS.COLLAB
      );

      const companies = await companiesModel.getAll();

      this.companies = this.companies = sortByKey(companies, "name");
    } catch (err) {
      this.$toasted.global.AppError({
        message: err.msg,
      });
    }
  },

  methods: {
    goBack() {
      window.history.back();
    },

    async selectCompany(companyId) {
      const company = await companiesModel.get(companyId);
      this.collaboratorsId = company.collaborators.map(collab => collab.id);
    },

    isDateInvalid(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },

    async createTask() {
      const result = await this.$validator.validateAll();

      if (this.isDateInvalid(this.task.deadline)) {
        this.$toasted.global.AppError({
          message: "Vous ne pouvez pas choisir une date antérieur à maintenant",
        });

        return;
      }

      if (this.task.user_id === "") {
        this.$toasted.global.AppError({
          message: this.$t("task.user-missing"),
        });

        return;
      }
      if (this.task.company_id === "") {
        this.$toasted.global.AppError({
          message: "Vous devez choisir une entreprise à associer",
        });

        return;
      }

      if (result) {
        this.task.deadline.setHours(new Date().getHours());
        try {
          await taskModel.create(this.task);
          this.$toasted.global.AppSucces({
            message: "La tâche a bien été créée",
          });

          await this.$router.push("/dashboard/tasks/tab-all");
        } catch (err) {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        }
      } else {
        this.$toasted.global.AppInfo({
          message: "Vous devez remplir les champs manquants",
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
#app {
  .tasks-create {
    .page-header {
      .page-header__tabs-search {
        margin-bottom: 0;
      }
      .page-title {
        font-size: toRem(30);
        @include for-lg {
          font-size: toRem(39);
        }
      }
    }
    .md-card {
      width: 770px;

      &-content {
        padding: 0;
      }

      .meep-form-column {
        width: 100%;
      }
    }
    .meep-form.md-theme-default
      .md-card-content
      .md-theme-default.md-field.md-datepicker {
      .md-input {
        height: 51px;
        @include for-lg {
          height: 66px;
        }
      }
      label {
        top: 17px;
        display: block;
        font-size: toRem(9);
        @include for-lg {
          top: 25px;
          font-size: toRem(12);
        }
      }
    }
    .ck-editor__top {
      display: block;
    }
    .md-layout .md-button {
      min-width: unset;
      min-height: unset;
      font-size: toRem(10);
      height: 33px;

      @include for-lg {
        height: 43px;
        font-size: toRem(14);
      }
      .md-ripple {
        padding: 0 toRem(36);
        @include for-lg {
          padding: 0 toRem(42);
        }
      }
    }
  }
}
</style>
